<template>
  <v-container id="dashboard" fluid tag="section">
    <v-form ref="form">
      <base-material-card icon="mdi-ticket-confirmation">
        <slot slot="title"><a @click="eventLink()">{{event.name}}</a> / <a @click="ticketTypeLink()">{{ticketType.name}}</a> / {{order.name}}</slot>
        <slot slot="rightButtons" v-if="ticket.id">
          <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
        </slot>
          <div v-if="showEditToggle">

            <v-select label="Event" menu-props="auto" item-value="id" item-text="name" v-on:change="reloadEvent" v-model="editTicket.eventId" :items="events">
            </v-select>

            <v-select label="Event Time" menu-props="auto" item-value="id" v-model="editTicket.eventTimeId" :items="eventTimes">
              <template v-slot:item="{ item }">{{utils.dateToShortString(item.start)}}</template>
              <template v-slot:selection="{ item }">{{utils.dateToShortString(item.start)}}</template>
            </v-select>

            <v-select label="Ticket Type" menu-props="auto" item-value="id" item-text="name" v-model="editTicket.ticketTypeId" :items="ticketTypes">
            </v-select>

            <div class="d-flex">
              <v-select label="Status" menu-props="auto" v-model="editTicket.status" :items="statuses"/>
            </div>

            <text-field label="Price" :prefix="getCurrencyPrefix(editTicket.currency)" v-model="editTicket.price" :rules="[priceRule]">
              <slot slot="tooltip">The price for the ticket.</slot> 
            </text-field>

            <text-field label="Booking Fee" :prefix="getCurrencyPrefix(editTicket.currency)" v-model="editTicket.bookingFee" :rules="[priceRule]">
              <slot slot="tooltip">The booking fee for the ticket.</slot> 
            </text-field>

            <v-row class="mt-5">
              <v-col cols="auto">
                <v-btn color="success" @click="updateTicket">Save</v-btn>
                <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
              </v-col>
              <v-spacer/>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="4">Name</v-col>
              <v-col cols="8">{{order.name}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Order Number</v-col>
              <v-col cols="8">
                <a @click="orderLink()"><b>{{order.orderNumber}}</b></a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Email</v-col>
              <v-col cols="8">{{order.email}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Phone</v-col>
              <v-col cols="8">{{order.phone}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Status</v-col>
              <v-col cols="8">{{ticket.status}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Event</v-col>
              <v-col cols="8">
                <a @click="eventLink()"><b>{{ticket.eventName}}</b></a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Ticket Type</v-col>
              <v-col cols="8">
                <a @click="ticketTypeLink()"><b>{{ticket.ticketTypeName}}</b></a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Event Time</v-col>
              <v-col cols="8">
                <a @click="eventTimeLink()"><b>{{utils.timezoneDateToShortString(eventTime.start, eventTime.timezone)}}</b></a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Price</v-col>
              <v-col cols="8">{{utils.priceDisplay(ticket.price, ticket.currency)}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Booking Fee</v-col>
              <v-col cols="8">{{utils.priceDisplay(ticket.bookingFee, ticket.currency)}}</v-col>
            </v-row>
          </div>
      </base-material-card>
    </v-form>
  </v-container>

</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js';
import Store from '@/services/Store.js';
import { getCurrencyPrefix } from '@/helpers/currency'

  export default {
    name: 'Order',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  

    data () {
      return {
        getCurrencyPrefix,
        showCreateTicketToggle: false,
        user: {},
        boxOffice: {},
        event: {},
        ticketType: {},
        eventTime: {},
        order: {},
        ticket: {},
        editTicket: {},
        eventTimes: [],
        events: [],
        statuses: [],
        reloadEventFlag: false,
        ticketTypes: [],
        showEditToggle: false,
      }
    },

    created() {
      this.ticket.id = this.$route.params.id;
      this.load(this.ticket);
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },
    
    methods: {

      priceRule(value) {
        return Utils.priceRuleAndCheckStripe(this.boxOffice, value);
      },

      async load() {
        this.ticket = await Api.post(this, "Ticket", "read", this.ticket);
        this.order = await Api.post(this, "Order", "read", {id:this.ticket.orderId});
        this.ticketType = await Api.post(this, "TicketType", "read", {id:this.ticket.ticketTypeId});
        this.eventTime = await Api.post(this, "EventTime", "read", {id:this.ticket.eventTimeId});
        this.loadEvent(this.ticket.eventId);
        this.boxOffice = await Api.post(this, "BoxOffice", "read", {id:this.ticket.boxOfficeId});
        this.events = await Api.post(this, "Event", "list", this.boxOffice);
        this.statuses = await Api.post(this, "Order", "statuses");
        Store.store(this.boxOffice, this.event);
        
        if (!this.ticket.currency) {
          this.ticket.currency = this.boxOffice.currency;
        }
      },

      eventLink() {
        this.$router.push("/dashboard/EventDetails/" + this.event.id);
      },

      orderLink() {
        this.$router.push("/dashboard/Order/" + this.order.id);
      },

      eventTimeLink() {
        this.$router.push("/dashboard/EventTime/" + this.eventTime.id);
      },

      ticketTypeLink() {
        this.$router.push("/dashboard/TicketType/" + this.ticketType.id);
      },

      async reloadEvent(id) {
        this.reloadEventFlag = true;
        this.ticket.eventTimeId = null;
        this.ticket.ticketTypeId = null;
        this.loadEvent(id);
      },

      async loadEvent(id) {
        this.event.id = id;
        this.event = await Api.post(this, "Event", "read", this.event);
        this.eventTimes = await Api.post(this, "EventTime", "list", this.event);
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
      },

      async updateTicket() {
        if (! await Utils.validate(this.$refs.form) ) {
          return;
        }
        this.ticket.price = Utils.priceToNumber(this.editTicket.price);
        this.ticket.bookingFee = Utils.priceToNumber(this.editTicket.bookingFee);
        this.ticket = await Api.post(this, "Ticket", "update", this.editTicket);
        this.load();
        this.hideEdit();
      },

      showEdit(show) {
        if (show) {
          this.editTicket = Utils.jsonCopy(this.ticket);
          this.editTicket.price = Utils.priceToString(this.ticket.price);
          this.editTicket.bookingFee = Utils.priceToString(this.ticket.bookingFee);
        }
        this.showEditToggle = show;
      },

      hideEdit() {
        this.showEditToggle = false;
      },

    }
  }
</script>
